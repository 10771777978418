import styled from 'styled-components';

export const LoadingCSS = styled.div`

#load{
  position: absolute;
  z-index: 1;
  top: 40%;
  left: 47%
}

.loader {
  position: absolute;
  right: 0;
  top: 35%;
  display: none;
  width: 20px;
  height: 20px;
  border-radius: 40px;
  box-sizing: border-box;
  border: solid 3px var(--grey2);
  border-top-color: #fff;
  animation: spin 1s infinite linear;
}

.circleloader {
  border-radius: 16px;
  width: 30px;
  height: 20px;
  background-color: rgba(255, 255, 255, 0.4);
}

  @keyframes spin { 
    100% { 
      transform: rotate(360deg); 
    } 
  } 

  @keyframes load {
      0% {opacity :0;}			
      100% {opacity : 1;}
  }
          
  @-webkit-keyframes load {
      0% {opacity :0;}			
      100% {opacity : 1;}	
  }

  @media only screen and (max-width: 1368px) {
    
  }

  @media only screen and (max-width: 800px) {
   
  }

  @media only screen and (max-width: 480px) {
     
  }
`;