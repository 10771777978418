import React, { useState, useEffect, useRef } from "react";
import { CssConfianca } from "./cssConfianca";

import Logo from "@/assets/imagens/logo-zest-branco.svg"

const Confianca = () => { 
 
  const [showConfiaSlide, setShowConfiaSlide] = useState(false);
  const confiaRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const mostrar = document.getElementById("confia");
      console.log("🚀 ~ handleScroll ~ confia:", mostrar)
  
      if (!mostrar) return;
  
      const rectF = mostrar.getBoundingClientRect();
      const faixaDE = rectF.top;
  
      if (faixaDE <= window.innerHeight - 150) {
        setShowConfiaSlide(true);
        window.removeEventListener("scroll", handleScroll);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <CssConfianca>       
      <div ref={confiaRef} id="confia" className={showConfiaSlide ? "aparecerConfia" : ""}>
        Por que essas <span/> marcas confiam <span/> na gente?
      </div>

      <div id="textConfia" className={showConfiaSlide ? "aparecerTexto" : ""}>
        Estabelecemos <strong>conexões autênticas entre marcas e  <span/> seus públicos</strong> através de uma comunicação <span/> direcionada e conectada.
      </div>

      <div id="logofim" className={showConfiaSlide ? "aparecerLogo" : ""}>
        <img src={Logo} alt="ZEST1" className="img" />
      </div>

      <div id="faixa1Conf" className={showConfiaSlide ? 'visivelFaixa1conf' : ''}></div>
      <div id="faixa2Conf" className={showConfiaSlide ? 'visivelFaixa2Conf' : ''}></div>
     
    </CssConfianca>
  );
  
}

export default Confianca;
