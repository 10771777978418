import styled from 'styled-components';

export const LoadingCSS = styled.div`
  display: none;
  width: 100%;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.6);
  height: 100%;
  z-index: 1100;
  top: 0;
  left: 0;

#load{
  position: fixed;
  z-index: 1;
  top: 40%;
  left: 48%
}
.circle {
  width: 60px;
  height: 60px;
  border-radius: 40px;
  box-sizing: border-box;
  border: solid 10px var(--pink);
  border-top-color: #fff;
  animation: spin 1s infinite linear;
}

.circleloader {
  border-radius: 16px;
  width: 80px;
  height: 20px;
  background-color: rgba(255, 255, 255, 0.4);
}

@keyframes spin { 
  100% { 
    transform: rotate(360deg); 
  } 
} 

  @keyframes load {
      0% {opacity :0;}			
      100% {opacity : 1;}
  }
          
  @-webkit-keyframes load {
      0% {opacity :0;}			
      100% {opacity : 1;}	
  }

  @media only screen and (max-width: 1368px) {
    img {
      margin: 0 auto;
      display: table;
      top: 30%;
      position: relative;
    }
  }

  @media only screen and (max-width: 800px) {
    img {
      margin: 0 auto;
      display: table;
      top: 20%;
      position: relative;
    }
  }

  @media only screen and (max-width: 480px) {
      img {
        top:25%;
        max-width:80%;
      }
  }
`;