import { Header } from "@/components/Header";
import { Component } from "react";
import { Container, Main } from "./styles";

class Error extends Component {   
      
    render() {
        return (            
            <Container>
                <Header/>
                <Main>                
                    <h1>OPS 404</h1> 
                </Main>
            </Container>                
        );
    }
    
}
  

  export default Error;