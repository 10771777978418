/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from "react";
import { CssFim } from "./cssFim";

import Logo from "@/assets/imagens/logo-zest-branco.svg"
import QRCODE from "@/assets/imagens/qrcode.jpg"

import instagram from "@/assets/imagens/instagram.png"
import linkedin from "@/assets/imagens/linkedin.png"

const Fim = () => { 
 
  const [showFim, setShowFim] = useState(false);
  const fimRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const mostrarFim = document.getElementById("fim");
  
      if (!mostrarFim) return;
  
      const rectF = mostrarFim.getBoundingClientRect();
      const faixaDE = rectF.top;
  
      if (faixaDE <= window.innerHeight - 250) {
        setShowFim(true);
        window.removeEventListener("scroll", handleScroll);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <CssFim>       
      <div ref={fimRef} id="fim" className={showFim ? "aparecerFim" : ""}>
        Vamos <span/> conversar?
      </div>

      <div id="infos" className={showFim ? "moverInfos" : ""}>
        
        <section  className="qrcode">
         <img src={QRCODE} alt="ZEST1" className="img qrcode" />
        </section>

        <section className="redes">
         {/*  <strong>Michele de Paula</strong>
          <span>Founder & CEO</span>  */}
          <a href="https://www.instagram.com/__zest1/" target="_blank"><img src={instagram} className="img" alt="instagram ZEST1" id="instagram"/></a>
          <a href="https://www.linkedin.com/company/zest1" target="_blank"><img src={linkedin} className="img" alt="linkedin ZEST1" id="linkedin"/></a>
        </section>
      </div>

      <div id="loguinho" className={showFim ? "mostraLogo" : ""}>
        <img src={Logo} alt="ZEST1" className="img" />
      </div>

      <div id="faixa1Result" className={showFim ? 'visivelFaixa1result' : ''}></div>
      <div id="faixa2Result" className={showFim ? 'visivelFaixa2Resul' : ''}></div>
     
    </CssFim>
  );
  
}

export default Fim;
