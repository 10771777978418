import styled from "styled-components";
import FundoAzul from "../../assets/imagens/fundoAzul.jpg";

export const CssConfianca = styled.div`

  width: 100%;
  height: 100vh;
  position: relative;
  padding: 0;
  margin: 0;
  background: url(${FundoAzul});
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow-x: hidden;

    /* quemconfia */
   #confia{
      width: 55%;
      position: absolute;
      top: 10%;
      left: -50%;   
      z-index:2;
      font-size: 5rem;
      font-family: "Roboto", sans-serif;
      font-optical-sizing: auto;
      font-weight:800;
      font-style: normal;
      color: #fff;
      line-height: 80px;
      
      span{
        display: block
      }
    }

  @keyframes moveFromConf {
    0% {
      left: -50%;
    }
    100% {
      left: 5%;
    }
  }

  .aparecerConfia{
    animation: moveFromConf 1.5s forwards;
  }


  #textConfia{
      width: 55%;
      position: absolute;
      top:  50%;
      left: -100%; 
      color: #fff;
      font-size: 1.2rem;
      line-height: 35px;
      
      span{
        display: block
      }
  }

  @keyframes moveFromTEXT {
    0% {
      left: -50%;
    }
    100% {
      left: 5%;
    }
  }

  .aparecerTexto{
    animation: moveFromTEXT 2.5s forwards;
  }

  #logofim{
      width: 180px;
      position: absolute;
      top:  70%;
      left: -50%; 
  }

  @keyframes mostraLogo {
    0% {
      left: -50%;
    }
    100% {
      left: 5%;
    }
  }

  .aparecerLogo{
    animation: mostraLogo 2s forwards;
  }

   /* faixa1 */
   #faixa1Conf{
    position: absolute;
    background: #fff;
    padding: 2% 2% 2% 10%;
    bottom: 0;
    right: -50%;
    width: 50%;
    height: 35%;
    z-index: 3;
    clip-path: polygon(25% 0, 100% 0, 90% 100%, 0 100%);
    opacity: 0;
    transition: opacity 1s ease-in-out;
  
  }

  @keyframes VisibleFaixa1Conf {
    0% {
      opacity: 0;
      right:-50%;
    }
    100% {
      opacity: 1;
      right:-10%; 
    }
  }

  .visivelFaixa1conf{
    animation: VisibleFaixa1Conf 2s forwards; 
  }
   

  /* faixa2 */
  #faixa2Conf{
    position: absolute;
    background: #fff;
    padding: 20px;
    top: 10%;
    right: -50%;
    width: 70%;
    height: 50%;
    z-index: 0;
    clip-path: polygon(28% 0, 100% 0, 90% 100%, 0 100%);
    opacity: 0;
    transition: opacity 1s ease-in-out;
  }

  @keyframes VisibleFaixa2Conf {
    0% {
      opacity: 0;
      right:-50%;
    }
    100% {
      opacity: 1;
      right:-20%; 
    }
  }

  .visivelFaixa2Conf{
    animation: VisibleFaixa2Conf 3s forwards; 
  }




  @media only screen and  (max-width: 1550px){
    
  
   
  } 

  @media only screen and  (max-width: 1370px){
    
   

  }


  @media only screen and  (max-width: 1280px){

   

  }


  @media only screen and  (max-width:1024px){

   

  }
 

  @media only screen and  (max-width: 915px){

    #confia{
      top: 5%;
      font-size: 5rem;
      width: 90%;

      span{
        display: none
      }
    }

    #textConfia{
      width: 90%;
      top: 33%;

      span{
        display: none
      }
    }

    #faixa1Conf{
      width: 65%;
      height: 19%;
    }

    #faixa2Conf{
      width: 70%;
     height: 30%;
     top: 45%;
    }
  }

  @media only screen and  (max-width: 480px){

   
    #confia {
        top: 5%;
        font-size: 3rem;
        width: 95%;
        line-height: 60px;
    }

    #textConfia {
      width: 90%;
      top: 35%;
   }

    #faixa1Conf{
      height: 20%;
    }

    #faixa2Conf{
      top: 52%;
      height: 25%;
    }

     #logofim {
      width: 130px;
      top: 80%;
  }


  }

  @media only screen and  (max-width: 415px){

     #confia {
        top: 5%;
        font-size: 3rem;
        width: 90%;
        line-height: 60px;
    }

    #textConfia {
      width: 90%;
      top: 40%;
   }

    #faixa1Conf{
      height: 20%;
    }

    #faixa2Conf{
      top: 58%;
      height: 20%;
    }

     #logofim {
      width: 130px;
      top: 70%;
  }


  }

  @media only screen and  (max-width: 395px){
  
    #confia {
      top: 10%;   
      font-size: 2.5rem;    
    }

     #textConfia {
         top: 37%;
    }

  }

  @media only screen and  (max-width: 330px){
    
    #confia {
        top: 5%;
        font-size: 2rem;
        line-height: 40px;
    }

     #textConfia {
        top: 28%;
    }

   #logofim {
      width: 110px;
      top: 80%;
  }
  
  }


`;