import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  
    *{
        margin: 0;
        padding: 0;
        box-sizing: border-box
    }

    a{
        text-decoration: none
    }

    *:focus {
        outline: none;
    }

    html{
        scroll-behavior: smooth;
        margin: 0;
        padding: 0;
    }


    body{
        font-family: "Montserrat", sans-serif;
        font-optical-sizing: auto;
        font-weight: <weight>;
        font-style: normal;
    }

    h1, h2, h3, h4, h5, h6, strong{
        font-weight: 600;
    }
 
    .mb{
        display: none
    }

    .pc{
        display: inline-block
    }

    .fullContent{
        max-width: 1920px;
        width: 100%; 
        margin: 0 auto;
        position: relative;
        padding: 0 1%;
    }

    .img{
        width: 100%;
        height: auto
    }

    @media only screen and (max-width: 1024px) {
        .fullContent{        
            padding: 2%;
            /* border:1px #ddd solid */
        }

    }



    @media only screen and (max-width: 800px) {

        .cont10, .cont20, .cont30, .cont33, .cont40, .cont50, .cont60, .cont70, .cont80, .cont90{
            width: 100%;
        }

        .mb{
            display: inline-block
        }

        .pc{
            display: none
        }
        
    }

    @media only screen and (max-width: 480px) {

        .Iframe{
        float: left;
        width: 330px;
        min-height: 450px;

            #frame{
                width: 100%;
                height: 450px;
            }
        }

        .title{
            font-size: 20px
        }

        .Portfolio img {
            width: 100%;
            display: inline;
        }

    }



`