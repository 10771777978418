/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {HeaderCss} from './styles';
import { useEffect, useState } from 'react';
//import Logo from "@/assets/imagens/p2mais-azul.jpg";

import { visible } from '@/services/functions';
import { Loading } from "@/helpers";

export function Header(){ 
    let larguraTela = window.innerWidth;
    console.log("🚀 ~ file: index.js:17 ~ Header ~ larguraTela:", larguraTela)

    const [scroll, setScroll] = useState("");

     useEffect(() => {  
        window.addEventListener("scroll", () => {
            if(window.scrollY > 60){                
                setScroll("fixed")
            }else if(window.scrollY < 60){
                setScroll("")
            }           
        });     
    },[scroll]);

     const fechaMenu = async () => {
        
        if(larguraTela < 850){
            visible("navigator")
        }
      
     };

  
    return(            
        <HeaderCss>
            <Loading/>  
            
            <header className={scroll} id="header">  
                <div className="fullContent">          
                    <div id="logo">
                      
                    </div> 

                    <ul id="navigator">
                        <li onClick={() => fechaMenu()}><a href="#capa">Quem Somos</a></li>                                      
                    </ul>

                    <div id="navMobile" onClick={() => visible("navigator")}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                   
                </div>
            </header> 
        </HeaderCss>  
    )
}