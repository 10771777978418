import React, { useState, useEffect, useRef } from "react";
import { CssOquefazemos } from "./CssOquefazemos";

import Mulher from "@/assets/imagens/mulher2.svg"
import planejamento from "@/assets/imagens/icone-planejamento.png"
import eventos from "@/assets/imagens/icone-eventos.png"
import criacao from "@/assets/imagens/icone-criacao.png"
import estrategia from "@/assets/imagens/icone-estrategia.png"
import producao from "@/assets/imagens/icone-producao.png"
import inbound from "@/assets/imagens/icone-inbound.png"

const OqueFazemos = () => { 
 
  const [showFazemos, setShowFazemos] = useState(false);
  const confiaFazemos = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const faixa1FElement = document.getElementById("faixa1F");
      console.log("🚀 ~ handleScroll ~ faixa1FElement:", faixa1FElement)
  
      if (!faixa1FElement) return;
  
      const rectF = faixa1FElement.getBoundingClientRect();
      const faixa1FTop = rectF.top;
  
      if (faixa1FTop <= window.innerHeight - 150) {
        setShowFazemos(true);
        window.removeEventListener("scroll", handleScroll);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <CssOquefazemos>  

      <div id="faixa1F" ref={confiaFazemos} className={showFazemos ? 'visivelFaixa1F' : ''}></div>
          <div id="mulherNegra" className={showFazemos ? 'apareceMulherNegra' : ''}>
            <img src={Mulher} alt="Mulher sorrindo" className="img"/>
          </div> 
      <div id="faixa2F" className={showFazemos ? 'visivelFaixa2F' : ''}></div>

      <div id="faixa3F" className={showFazemos ? 'visivelFaixa3F' : ''}>

          <div id="icones">
            <div className="itens">
              <img src={planejamento} alt="Planejamento" className="img" title="Planejamento estratégico"/>
              <span> <strong>Planejamento</strong><br/> estratégico</span>
            </div>
            <div className="itens">
              <img src={eventos} alt="Planejamento" className="img" title="Eventos & Ações Promocionais"/>
              <span><strong>Eventos & Ações</strong> <br/> Promocionais</span>
            </div>
            <div className="itens">
              <img src={criacao} alt="Planejamento" className="img" title="Criação de campanhas"/>
              <span><strong>Criação de</strong><br/> campanhas</span>
            </div>
            <div className="itens">
              <img src={estrategia} alt="Estratégia & Gestão" className="img" title="Estratégia & Gestão Marketing de Influência"/>
              <span><strong>Estratégia & Gestão de</strong><br/> Marketing de Influência</span>
            </div>
            <div className="itens">
              <img src={producao} alt="Produção de Conteúdo & Gestão de Redes Sociais" className="img" title="Produção de Conteúdo & Gestão de Redes Sociais"/>
              <span>Produção de <strong>Conteúdo</strong> & <br/> <strong>Gestão de Redes Sociais</strong></span>
            </div>
            <div className="itens">
              <img src={inbound} alt="Inbound & Estratégia de info Produto" className="img" title="Inbound & Estratégia de info Produto"/>
              <span>Inbound & Estratégia de <br/><strong>info Produto</strong></span>
            </div>
          </div>

      </div>

      <div id="oqfazemos" className={showFazemos ? "aparecerFazemos" : ""}>
        O que fazemos <span/> pela sua marca<span/>
        <div>
          ALGUMAS DE NOSSAS <span/> <strong>ENTREGAS PRÁTICAS</strong>
        </div>
      </div>    
     
    </CssOquefazemos>
  );
  
}

export default OqueFazemos;
