import React, { useState, useEffect, useRef } from "react";
import { CssResultados } from "./cssResultados";

import Logo from "@/assets/imagens/logo-zest-branco.svg"

const Resultados = () => { 
 
  const [showResultado, setShowResultado] = useState(false);
  const confiaRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const mostrar = document.getElementById("resultado");
      console.log("🚀 ~ handleScroll ~ resultado:", mostrar)
  
      if (!mostrar) return;
  
      const rectF = mostrar.getBoundingClientRect();
      const faixaDE = rectF.top;
  
      if (faixaDE <= window.innerHeight - 180) {
        setShowResultado(true);
        window.removeEventListener("scroll", handleScroll);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <CssResultados>       
      <div ref={confiaRef} id="resultado" className={showResultado ? "aparecerResultado" : ""}>
        Não entregamos <span/> projetos. Entregamos <span/> resultados.
      </div>

      <div id="result" className={showResultado ? "textoResultado" : ""}>
        Empregamos as melhores práticas para garantir que cada campanhas <span/> ou evento seja um case de sucesso, fazendo com <span/> que os clientes obtenham alto desempenho em cada projeto <span/>  proposto, alcacem suas metas e conquistem seus objetivos de <span/> negócio. <strong>É a promessa de que seu investimento terá retorno.</strong>
      </div>

      <div id="loguinho" className={showResultado ? "mostraLogo" : ""}>
        <img src={Logo} alt="ZEST1" className="img" />
      </div>

      <div id="faixa1Result" className={showResultado ? 'visivelFaixa1result' : ''}></div>
      <div id="faixa2Result" className={showResultado ? 'visivelFaixa2Resul' : ''}></div>
     
    </CssResultados>
  );
  
}

export default Resultados;
