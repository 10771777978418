import styled from "styled-components";
import FundoAzul from "../../assets/imagens/fundoAzul.jpg";

export const CssResultados = styled.div`

  width: 100%;
  height: 100vh;
  position: relative;
  padding: 0;
  margin: 0;
  background: url(${FundoAzul});
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;

    /* quemconfia */
   #resultado{
      width: 55%;
      position: absolute;
      top: 10%;
      left: -50%;   
      z-index:2;
      font-size: 5rem;
      font-family: "Roboto", sans-serif;
      font-optical-sizing: auto;
      font-weight:700;
      color: #fff;
      line-height: 80px;
      
      span{
        display: block
      }
    }

  @keyframes moveFromConf {
    0% {
      left: -50%;
    }
    100% {
      left: 5%;
    }
  }

  .aparecerResultado{
    animation: moveFromConf 1.5s forwards;
  }


  #result{
      width: 55%;
      position: absolute;
      top:  50%;
      left: -100%; 
      color: #fff;
      font-size: 1.2rem;
      line-height: 35px;
      
      span{
        display: block
      }
  }

  @keyframes moveFromTEXT {
    0% {
      left: -50%;
    }
    100% {
      left: 5%;
    }
  }

  .textoResultado{
    animation: moveFromTEXT 2.5s forwards;
  }

  #loguinho{
      width: 180px;
      position: absolute;
      top:  75%;
      left: -50%; 
  }

  @keyframes mostraLogo {
    0% {
      left: -50%;
    }
    100% {
      left: 5%;
    }
  }

  .mostraLogo{
    animation: mostraLogo 2s forwards;
  }

   /* faixa1 */
   #faixa1Result{
    position: absolute;
    background: #fff;
    padding: 2% 2% 2% 10%;
    bottom: 0;
    right: -50%;
    width: 50%;
    height: 35%;
    z-index: 3;
    clip-path: polygon(25% 0, 100% 0, 90% 100%, 0 100%);
    opacity: 0;
    transition: opacity 1s ease-in-out;
  
  }

  @keyframes VisibleFaixa1Conf {
    0% {
      opacity: 0;
      right:-50%;
    }
    100% {
      opacity: 1;
      right:-10%; 
    }
  }

  .visivelFaixa1result{
    animation: VisibleFaixa1Conf 2s forwards; 
  }
   

  /* faixa2 */
  #faixa2Result{
    position: absolute;
    background: #fff;
    padding: 20px;
    top: 10%;
    right: -50%;
    width: 70%;
    height: 50%;
    z-index: 0;
    clip-path: polygon(28% 0, 100% 0, 90% 100%, 0 100%);
    opacity: 0;
    transition: opacity 1s ease-in-out;
  }

  @keyframes VisibleFaixa2Conf {
    0% {
      opacity: 0;
      right:-50%;
    }
    100% {
      opacity: 1;
      right:-20%; 
    }
  }

  .visivelFaixa2Resul{
    animation: VisibleFaixa2Conf 3s forwards; 
  }




  @media only screen and  (max-width: 1550px){
    
    #faixa2Result{
      width: 60%;
    }
   
  } 

  @media only screen and  (max-width: 1370px){
    
    #resultado{
      font-size: 4rem;
    }

    @keyframes VisibleFaixa2Conf {
    0% {
      opacity: 0;
      right:-50%;
    }
    100% {
      opacity: 1;
      right:-25%; 
    }
  }


  }


  @media only screen and  (max-width: 1280px){

   

  }


  @media only screen and  (max-width:1024px){

    #resultado{
      top: 7%;
      font-size: 3.5rem;
    }

    #result{
      width: 45%;
      top: 45%;
      span{
        display: none
      }
    }

  }
 

  @media only screen and  (max-width: 915px){

    #resultado{
      width: 100%
    }

    #confia{
      top: 5%;
      font-size: 5rem;
      width: 90%;

      span{
        display: none
      }
    }

    #result{
      width: 55%;
      top: 35%;

      span{
        display: none
      }
    }

    #faixa1Result{
      width: 65%;
      height: 19%;
    }

    #faixa2Result{
      width: 70%;
     height: 30%;
     top: 45%;
    }
  }

  @media only screen and  (max-width: 480px){

    #resultado {
      top: 5%;
      font-size: 2rem;
      line-height: 40px;
    }
   
    #confia {
        top: 5%;
        font-size: 3rem;
        width: 95%;
        line-height: 60px;
    }

    #result {
      width: 90%;
      top: 25%;
   }

    #faixa1Result{
      height: 20%;
    }

    #faixa2Result{
      top: 52%;
      height: 25%;
    }

     #loguinho {
      width: 130px;
      top: 80%;
  }


  }

  @media only screen and  (max-width: 415px){

     #confia {
        top: 5%;
        font-size: 3rem;
        width: 90%;
        line-height: 60px;
    }

    #result {
      width: 90%;
      top: 30%;
      font-size: 16px;
   }

    #faixa1Result{
      height: 20%;
    }

    #faixa2Result{
      top: 58%;
      height: 20%;
      display: none
    }

     #loguinho {
      width: 120px;
      top: 75%;
  }


  }

  @media only screen and  (max-width: 395px){
  
    #resultado {
      top: 4%;
      font-size: 2.2rem;
      line-height: 55px; 
    }

     #result {
         top: 30%;
    }

  }

  @media only screen and  (max-width: 330px){
    
    #confia {
        top: 5%;
        font-size: 2rem;
        line-height: 40px;
    }

     #result {
        top: 32%;
        line-height: 25px;
        font-size: 16px;
    }

   #loguinho {
      width: 110px;
      top: 80%;
  }
  
  }


`;