import styled from "styled-components";
import FundoRosa from "../../assets/imagens/fundoRosa.jpg";

export const CssMarcas = styled.div`

  width: 100%;
  height: 100vh;
  position: relative;
  padding: 0;
  margin: 0;
  background: url(${FundoRosa});
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow-x: hidden;

    /* quemconfia */
   #quemconfia{
      width: 55%;
      position: absolute;
      top: -50%;
      left:10%;   
      z-index:2;
      font-size: 4rem;
      font-family: "Roboto", sans-serif;
      font-optical-sizing: auto;
      font-weight:700;
      color: #fff
    }

  @keyframes moveFrom {
    0% {
      top: -50%;
    }
    100% {
      top: 5%;
    }
  }

  .apareceConfia{
    animation: moveFrom 1.5s forwards;
  }


  #alugmasMarcas{
      width: 35%;
      position: absolute;
      top: 9%;
      right: -50%; 
      color: #fff;
      font-size: 1rem;
      letter-spacing: 8px;
      line-height: 25px;
      
      span{
        display: block
      }
  }

  @keyframes moveFromRight {
    0% {
      right: -50%;
    }
    100% {
      right: 5%;
    }
  }

  .apareceTexto{
    animation: moveFromRight 1.5s forwards;
  }

  /* faixa */
  #faixa{
      position: absolute;
      background: #fff;
      padding: 2% 2% 2% 10%;
      bottom: 0;
      right: -30%;
      width: 125%;
      height: 75%;
      z-index: 3;
      clip-path: polygon(20% 0, 100% 0, 90% 100%, 0 100%);
      opacity: 0;
      transition: opacity 1s ease-in-out;        
    }

    @keyframes VisibleFaixa1 {
      0% {
        opacity: 0;
        right:-30%;
      }
      100% {
        opacity: 1;
        right:-15%; 
      }
    }

    .visivelFaixa{
      animation: VisibleFaixa1 2s forwards; 
    }

    /* marcas1 */
    #marcas1{
      width: 85%;
      top:5%;
      right: -10%;
      position: absolute;
      /* border: 1px #333 solid; */

      img{
        max-width: 300px;
        padding: 0 30px
      }
    }

    @keyframes VisibleMarca1 {
      0% {
        opacity: 0;
        right:-90%;
      }
      100% {
        opacity: 1;
        right:-10%; 
      }
    }

    .visivelmarcas1{
      animation: VisibleMarca1 2s forwards; 
    }

     /* marcas2 */
     #marcas2{
      width: 90%;
      top: 35%;
      right: -10%;
      position: absolute;
      /* border: 1px #333 solid */;

      img{
        max-width: 300px;
        padding: 0 30px
      }
    }

    @keyframes VisibleMarca2 {
      0% {
        opacity: 0;
        right:-90%;
      }
      100% {
        opacity: 1;
        right:-8%; 
      }
    }

    .visivelmarcas2{
      animation: VisibleMarca2 2.5s forwards; 
    }

     /* marcas3 */
     #marcas3{
      width: 85%;
      top:68%;
      right: -10%;
      position: absolute;
     /*  border: 1px #333 solid; */

      img{
        max-width: 300px;
        padding: 0 30px
      }
    }

    @keyframes VisibleMarca3 {
      0% {
        opacity: 0;
        right:-95%;
      }
      100% {
        opacity: 1;
        right:0%; 
      }
    }

    .visivelmarcas3{
      animation: VisibleMarca3 3s forwards; 
    }



  @media only screen and  (max-width: 1550px){
    
    #quemconfia{
      font-size: 4rem;
    }
   
  } 

  @media only screen and  (max-width: 1370px){
    
    #quemconfia{
      left: 6%;
    }
   
    @keyframes moveFromRight {
    0% {
      right: -50%;
    }
    100% {
      right: 0%;
    }
  }


  }


  @media only screen and  (max-width: 1280px){

   

  }


  @media only screen and  (max-width:1024px){

    #quemconfia {
      font-size: 3rem;
    }
    #alugmasMarcas{
      width: 40%;  
      top: 6%;
      font-size: 14px;
    }

    #faixa{
      height: 82%;
    }
    
    #marcas1 img, #marcas2 img, #marcas3 img{
      max-width: 260px;
    }

  }
 

  @media only screen and  (max-width: 915px){

    #quemconfia{
      font-size: 3rem;
    }

    #alugmasMarcas{
      width: 45%;
    }
    
    @keyframes moveFrom {
      0% {
        top: -50%;
      }
      100% {
        top: 2%;
      }
    }

    #marcas1 img, #marcas2 img, #marcas3 img{
      max-width: 300px
    }

    #marcas2{
      top: 40%;
    }

    #marcas3{
      top: 75%;
    }

  }

  @media only screen and  (max-width: 480px){

    #faixa {
        height:65%;
    }

    #quemconfia{
      width: 100%;
      left: 0;
      font-size: 3rem; 
      text-align: center;
    }

    @keyframes moveFrom {
      0% {
        top: -50%;
      }
      100% {
        top: 8%;
      }
    }


    #alugmasMarcas {
      width: 90%;      
      top: 25%;
   }

   @keyframes VisibleMarca1 {
      0% {
        opacity: 0;
        right:-90%;
      }
      100% {
        opacity: 1;
        right:52%; 
      }
    }

   #marcas1{
    width: 33%;
    top:15%;
   }


   @keyframes VisibleMarca2 {
      0% {
        opacity: 0;
        right:-90%;
      }
      100% {
        opacity: 1;
        right:18%; 
      }
    }

    #marcas2{
      width: 33%;
      top: 15%;
    }


    @keyframes VisibleMarca3 {
      0% {
        opacity: 0;
        right:-95%;
      }
      100% {
        opacity: 1;
        right:-2%; 
      }
    }

    #marcas3 {
      top:68%;
      width: 85%;

      img{
        max-width: 160px;
      }
   }

  }

  @media only screen and  (max-width: 414px){

    
    #quemconfia{
      font-size: 2.5rem;
    }
    
    @keyframes moveFrom {
      0% {
        top: -50%;
      }
      100% {
        top: 5%;
      }
    }

     #alugmasMarcas {
        width: 100%;
        top: 22%;
        text-align: center;
    }

  }

  @media only screen and  (max-width: 395px){
  
    #faixa {
      height: 50%;
  }

  @keyframes moveFrom {
      0% {
        top: -50%;
      }
      100% {
        top: 5%;
      }
    }


  #alugmasMarcas {
     width: 90%;
      top: 30%;
  }

  }

  @media only screen and  (max-width: 330px){
    
    #quemconfia{
      font-size: 2.3rem;
    }

    #alugmasMarcas {
        width: 96%;
        top: 22%;
        text-align: center;
    }

    #faixa {
      height: 55%;
   }

    #marcas1 img, #marcas2 img{
      padding: 0 15px;
    }

     #marcas3 {
        top: 70%;
        width: 91%;
    }
  
  }


`;