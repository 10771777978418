import { LoadingCSS } from "./styles";

export default function Loading(){

    return(
        <LoadingCSS id="Loading" className="cortina">
        <div id="load">
            <div className="circle"></div>
        </div>
      </LoadingCSS>       
    )
}