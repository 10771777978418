import styled from 'styled-components';

export const HeaderCss = styled.header`

   #header{
        width: 100%; 
        padding: 5px 0;
        height: 90px;
        top: 0;
        left: 0;
        z-index: 50; 
        float: left;
        transition: 0.5s;
        position: fixed;
        top: 0;
        left:0;
        /* border-bottom: 1px #ddd solid; */
        .whats{
            width: 40px;
            position: absolute;
            top: 10px;
            right: 10px;
        }

    }

 
    .fixed{
        transition: 0.5s;
        z-index: 1000;
        background: #0155af;

    }

    .calculadora{
        -webkit-box-shadow: 0px 6px 0px -3px rgba(235,142,56,1);
        -moz-box-shadow: 0px 6px 0px -3px rgba(235,142,56,1);
        box-shadow: 0px 6px 0px -3px rgba(235,142,56,1); 

        :hover{
            color: #fff;
            transition: 0.5s
        }

        float: right;
        margin: 20px 0 0 0;
        z-index: 20;
        position: relative;
    }

    #navMobile{
        width: 30px;
        height: 40px;
        display: none;
        position: absolute;
        top: 30px;
        left: 50px;
        cursor: pointer;

        span{
            float: left;
            width: 100%;         
            background: #333;
            height: 3px;
            border-radius: 20px;
            margin: 3px 0;
        }
    }
       

    #logo{
        float: left;
        width: 280px;
        padding: 0;
        margin: 15px 0 0 0;

        img{
            width: 100%;          
        }
    }

    ul{       
        width: 100%;
        position: absolute;
        top: 30px;
        left:0;
        text-align: center;

        li{
            display: inline-block;
            padding: 0 20px;

            a{
                color: #fff;
                font-size: 16px;
                font-weight: 300;
                transition: 0.5s;

                :hover{
                    color: #fac23b;
                    transition: 0.5s
                }
            }
        }
    }
    

        

@media only screen and  (max-width: 1680px){
  

}
  
@media only screen and  (max-width: 1440px){   

   
}

@media only screen and  (max-width: 1366px){ 
   
}

@media only screen and  (max-width: 1280px){


}

@media only screen and  (max-width: 1024px){
 

   
 

}

@media only screen and  (max-width:850px){

  
    #navMobile{
        left: 20px;
        display: block;

        span{
            padding: 2px;
            background: #fff;
        }
    } 

    ul{
        display: none;
        top: 65px;
        left: 0;
        width: 100%;
        text-align: left;
        padding: 4%;
        background: #0155af;

        li{
            float: left;
            width: 100%;
            padding: 20px;

            a{
                font-size: 20px;
                color: #fff;
            }
        }
    }


    #logo{
        margin: 0% 0 0 28%;
    }

    .calculadora{
        margin: 2px 15px 0 0;
    }

  

}

@media only screen and  (max-width: 480px){

    .calcMobile{
        display: none!important
   }

    .calculadora{
        right: 5px;
    }

   
  
    #logo{
      margin: 4% 0 0 0;
      width: 100%;
      text-align: center;

      img{
        width:65%
      }
    }

    .button{
        padding: 10px 15px;
        font-size: 12px;
    }

   
}

@media only screen and  (max-width: 414px){

 
   
}

@media only screen and  (max-width: 375px){
   

}

@media only screen and  (max-width: 320px){

}
    
`