import styled from "styled-components";
import FundoAzul from "../../assets/imagens/fundoAzul.jpg";

export const CssObjetivos = styled.div`

  width: 100%;
  height: 100vh;
  position: relative;
  padding: 0;
  margin: 0;
  background: url(${FundoAzul});
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;

    /* objetivo */
   #objetivo{
      width: 80%;
      position: absolute;
      top: 20%;
      left: -50%;   
      z-index:2;
      font-size:6rem;
      font-family: "Roboto", sans-serif;
      font-optical-sizing: auto;
      font-weight:700;
      color: #fff;
      line-height: 95px;
      z-index: 4;
      
      span{
        display: block
      }
    }

  @keyframes moveFromConf {
    0% {
      left: -50%;
    }
    100% {
      left: 5%;
    }
  }

  .aparecerObj{
    animation: moveFromConf 1.5s forwards;
  }


  #obj{
      width: 55%;
      position: absolute;
      top:  9%;
      left: -100%; 
      color: #fff;
      font-size: 1.2rem;
      line-height: 35px;
      font-height: 300;
      text-transform: uppercase;
      letter-spacing: 10px;
      
      span{
        display: block
      }
  }

  @keyframes moveFromTEXT {
    0% {
      left: -50%;
    }
    100% {
      left: 5%;
    }
  }

  .textoobj{
    animation: moveFromTEXT 2.5s forwards;
  }


   /* mulher */
   #mulherObj{
    width: 55%;
    position: absolute;
    bottom: -4px;
    right:-60%;   
    z-index:2
  }

  @keyframes moveMulherObj {
    0% {
      right:-50%;
    }
    100% {
      right:-10%; 
    }
  }

  .apareceMulherObj{
    animation: moveMulherObj 2s forwards;
  }


  #loguinhoObj{
      width: 150px;
      position: absolute;
      bottom:  4%;
      left: -50%; 
  }

  @keyframes mostraLogoObj {
    0% {
      left: -50%;
    }
    100% {
      left: 5%;
    }
  }

  .mostraLogoObj{
    animation: mostraLogoObj 2s forwards;
  }

  
  /* faixa2 */
  #faixa2Obj{
    position: absolute;
    background: #fff;
    padding: 20px;
    bottom: 0%;
    right: -50%;
    width: 75%;
    height:30%;
    z-index: 0;
    clip-path: polygon(15% 0, 100% 0, 90% 100%, 0 100%);
    opacity: 0;
    transition: opacity 1s ease-in-out;
  }

  @keyframes VisibleFaixa2Conf {
    0% {
      opacity: 0;
      right:-50%;
    }
    100% {
      opacity: 1;
      right:-15%; 
    }
  }

  .visivelFaixa2Resul{
    animation: VisibleFaixa2Conf 2s forwards; 
  }




  @media only screen and  (max-width: 1550px){
    
    #objetivo{
      width: 80%;
      position: absolute;
      top:17%;
      font-size:5.5rem;
    }

   
  } 

  @media only screen and  (max-width: 1370px){
    
    #mulherObj{
      width: 65%;
    }
    
    @keyframes moveMulherObj {
    0% {
      right:-50%;
    }
    100% {
      right:-15%; 
    }
  }


    @keyframes VisibleFaixa2Conf {
    0% {
      opacity: 0;
      right:-50%;
    }
    100% {
      opacity: 1;
      right:-25%; 
    }
  }

   #objetivo {
    width: 80%;
    position: absolute;
    top: 20%;
    font-size: 5rem;
  }

  #obj{
    top:12%
  }


  }


  @media only screen and  (max-width: 1280px){

    #obj{
      top:15%
    }


     #objetivo {
      font-size: 4.5rem;
      line-height: 80px;
      top: 25%;
    }

  }


  @media only screen and  (max-width:1024px){

    #mulherObj{
      width: 85%;
    }
    
    @keyframes moveMulherObj {
    0% {
      right:-50%;
    }
    100% {
      right:-25%; 
    }
  }

   
    #obj{
      width: 100%;
      top: 10%;
      span{
        display: none
      }
    }

    #objetivo {
      font-size: 3rem;
      line-height: 71px;
      top: 18%;
    }

  }
 

  @media only screen and  (max-width: 915px){

   
    
    #obj{     
      top: 5%;
    }
   
   
     #mulherObj {
        width: 100%;
    }

   #objetivo {
      font-size: 3rem;
      line-height: 71px;
      top: 10%;
      width:90%;

      span{
        display: none
      }
    }

    #faixa2Obj{
      width: 100%;
      height: 20%;
    }

  }

  @media only screen and  (max-width: 480px){

    #objetivo {
      font-size: 3rem;
      line-height: 60px;
      top: 16%;
  }

   #obj {
      top: 5%;
      width: 85%;
      font-size: 15px;
  }

     
     #loguinhoObj {
      width: 130px;  
      bottom:15% 
    }


  }

  @media only screen and  (max-width: 415px){

     #objetivo {
      font-size: 2rem;
      line-height: 40px;
      top: 18%;
      width: 70%
  }

    #loguinhoObj {
      width: 100px;  
      bottom: 10%  
    }

    #obj {
      top: 4%;
      font-size: 15px;
      letter-spacing: 3px;
  }


  }

  @media only screen and  (max-width: 395px){
  
    #objetivo {
      font-size: 2.2rem;
      line-height: 55px;
      top: 16%;
      width: 90%;
    }

  }

  @media only screen and  (max-width: 330px){
    
   

   #loguinhoObj {
      width: 110px;
      top: 80%;
  }
  
  }


`;