import styled from "styled-components";
import FundoAzul from "../../assets/imagens/fundoRosa.jpg";

export const CssFim = styled.div`

  width: 100%;
  height: 100vh;
  position: relative;
  padding: 0;
  margin: 0;
  background: url(${FundoAzul});
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;

    /* quemconfia */
   #fim{
      width: 55%;
      position: absolute;
      top: 10%;
      left: -50%;   
      z-index:2;
      font-size: 7rem;
      font-family: "Roboto", sans-serif;
      font-optical-sizing: auto;
      font-weight:700;
      color: #fff;
      line-height: 80px;
      
      span{
        display: block
      }
    }

  @keyframes moveFromConf {
    0% {
      left: -50%;
    }
    100% {
      left: 5%;
    }
  }

  .aparecerFim{
    animation: moveFromConf 1.5s forwards;
  }


  #infos{
      width: 45%;
      position: absolute;
      top:  40%;
      left: -70%; 
      color: #fff;
      font-size: 1rem;
      line-height: 35px;
      
      span{
        display: block
      }

      section{
        float: left;
        width: 50%;
        padding:0 10px;
      }

      .qrcode{
         padding: 0 10px;
        width: 35%; 
        img{
          width:100%;  
          border-radius: 20px;      
        }        
      }

      .redes{
        margin-top: 6%;
        border-left: 2px #fff solid;
        min-height: 140px;
        padding: 5% 0 0 2%;

        strong{
          font-size: 30px;
          display: block
        }

        span{
          display: block
        }

        img{
          max-width:50px;
          margin: 0 10px 0 0
        }
      }
  }

  @keyframes moveInfos {
    0% {
      left: -50%;
    }
    100% {
      left: 5%;
    }
  }

  .moverInfos{
    animation: moveInfos 2.5s forwards;
  }

  #loguinho{
      width: 180px;
      position: absolute;
      top:  75%;
      left: -50%; 
  }

  @keyframes mostraLogo {
    0% {
      left: -50%;
    }
    100% {
      left: 5%;
    }
  }

  .mostraLogo{
    animation: mostraLogo 2s forwards;
  }

   /* faixa1 */
   #faixa1Result{
    position: absolute;
    background: #fff;
    padding: 2% 2% 2% 10%;
    bottom: 0;
    right: -50%;
    width: 50%;
    height: 35%;
    z-index: 3;
    clip-path: polygon(25% 0, 100% 0, 90% 100%, 0 100%);
    opacity: 0;
    transition: opacity 1s ease-in-out;
  
  }

  @keyframes VisibleFaixa1Conf {
    0% {
      opacity: 0;
      right:-50%;
    }
    100% {
      opacity: 1;
      right:-10%; 
    }
  }

  .visivelFaixa1result{
    animation: VisibleFaixa1Conf 2s forwards; 
  }
   

  /* faixa2 */
  #faixa2Result{
    position: absolute;
    background: #fff;
    padding: 20px;
    top: 10%;
    right: -50%;
    width: 70%;
    height: 50%;
    z-index: 0;
    clip-path: polygon(28% 0, 100% 0, 90% 100%, 0 100%);
    opacity: 0;
    transition: opacity 1s ease-in-out;
  }

  @keyframes VisibleFaixa2Conf {
    0% {
      opacity: 0;
      right:-50%;
    }
    100% {
      opacity: 1;
      right:-20%; 
    }
  }

  .visivelFaixa2Resul{
    animation: VisibleFaixa2Conf 3s forwards; 
  }




  @media only screen and  (max-width: 1550px){
    
    #faixa2Result{
      width: 60%;
    }

    #infos .redes{
        margin-top: 4%;
        padding: 5% 0 0 2%;
    }
   
  } 

  @media only screen and  (max-width: 1370px){
    
    #resultado{
      font-size: 4rem;
    }

    @keyframes VisibleFaixa2Conf {
    0% {
      opacity: 0;
      right:-50%;
    }
    100% {
      opacity: 1;
      right:-25%; 
    }
  }

  #infos .redes{
        margin-top: 3%;
        padding: 5% 0 0 2%;
    }

  }


  @media only screen and  (max-width: 1280px){

    #infos{
      width: 55%;
    }

  }


  @media only screen and  (max-width:1024px){
    
    #infos{
      width: 65%;
    }


 

  }
 

  @media only screen and  (max-width: 915px){

     #infos {
        width: 85%;
    }


    #faixa1Result{
      width: 65%;
      height: 19%;
    }

    #faixa2Result{
      width: 70%;
     height: 30%;
     top: 45%;
    }
  }

  @media only screen and  (max-width: 480px){

    #fim{
      font-size: 4rem;
      line-height: 65px;
      top: 5%;
      width: 90%;
      text-align: center
    }
   
     #infos{
      top: 28%;
      text-align: center;
      width: 90%;

      .qrcode {
          padding: 0 10px;
          width: 100%;
          img{
            max-width: 150px;
          }
      }

      .redes{
        border: none
      }
     } 
   

    #infos section {
      width: 99%;
  }

  
    #faixa1Result{
      height: 20%;
    }

    #faixa2Result{
      top: 52%;
      height: 25%;
    }

    @keyframes VisibleFaixa2Conf {
    0% {
      opacity: 0;
      right:-50%;
    }
    100% {
      opacity: 1;
      right:-45%; 
    }
  }


     #loguinho {
      width: 130px;
      top: 80%;
  }


  }

  @media only screen and  (max-width: 415px){

  
   

    #faixa1Result{
      height: 20%;
    }

    #faixa2Result{
      top: 58%;
      height: 20%;
      display: none
    }

     #loguinho {
      width: 120px;
      top: 75%;
  }


  }

  @media only screen and  (max-width: 395px){
  
    #resultado {
      top: 4%;
      font-size: 2.2rem;
      line-height: 55px; 
    }


  }

  @media only screen and  (max-width: 330px){
 
   #loguinho {
      width: 110px;
      top: 80%;
  }
  
  }


`;