import styled from 'styled-components';

export const Container = styled.header`
    /* background: var(--blue);  */
    width: 100%;
    position: relative;

`

export const Main = styled.div`
   
    width: 100%;
    position: relative;
    padding: 5rem;
    text-align: center;

`