/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { CssHome } from "./cssHome";

import mulher from "@/assets/imagens/mulher.svg"
import Logo from "@/assets/imagens/logo-zest-branco.svg"
import Marcas from "./Marcas";
import Confianca from "./Confianca";
import OqueFazemos from "./Oquefazemos";
import Resultados from "./Resultados";
import Objetivos from "./Objetivos";
import Fim from "./Fim";


//logo-zest1-branco
const Index = () => {
  document.title = "Zest One";
  
  //let larguraTela = window.innerWidth;
  //let alturaTela = window.innerHeight;
  //alert(alturaTela)
 
  //CAPA
  const [showLogo, setShowLogo] = useState(true);
  const [showMulher, setShowMulher] = useState(false);
  const [showFaixa, setShowFaixa] = useState(false);
  const [showZest, setShowZest] = useState(false);
 

 //setShowLogo setShowMulher
  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowLogo(false);
      setShowMulher(true)      
    }, 1000);
    return () => clearTimeout(timeout);
  }, []); // Executa apenas uma vez ao montar o componente 
  
  //setShowFaixa setShowZest
  useEffect(() => {
    const timeoutFaixa = setTimeout(() => {
      setShowFaixa(true)
      setShowZest(true)
    }, 1500);
    return () => clearTimeout(timeoutFaixa);
  }, []); // Executa apenas uma vez ao montar o componente
 

  return (
    <>
    <CssHome>
      <div id="principal">        
         
        <div id="logo" className={showLogo ? '' : 'esconder-logo'}>
          <img src={Logo} alt="Zest One" title="Zest One" className="img"/>
        </div>

        <div id="faixa2" className={showFaixa ? 'visivelFaixa2' : ''}></div>

        <div id="mulher" className={showMulher ? 'apareceMulher' : ''}>
          <img src={mulher} alt="Mulher sorrindo" className="img"/>
        </div> 

        <div id="faixa1" className={showFaixa ? 'visivelFaixa1' : ''}>
          <p>
            Somos ágeis e colaborativos, <strong>estabelecemos<span/> conexões significativas entre marcas e seus públicos<span/> de interesse,</strong> seja eles times internos, parceiros de<span/> negócios e seus clientes finais.
          </p>
        </div>

        <div id="texto" className={showZest ? 'mostraZest' : ''}>
          <div id="somos">
            SOMOS A             
          </div>

          <div id="zest">
            Zest <span/> One
          </div>      

          <div id="textoCurto" className={showZest ? 'mostraTextoCruto' : ''}>
            Uma agência de comunicacão <span/>
            integrada que traduz os objetivos de <span/>
            negócio e a estratégia de marca em <span/>
            <strong>ações concretas e impactantes.</strong>
          </div>

          <div id="loguinho" className={showZest ? 'mostraLoguinho' : ''}>
            <img src={Logo} alt="Zest One" title="Zest One" className="img"/>
          </div>

        </div>  

      </div>    
    </CssHome>

    <Marcas />

    <Confianca />

    <OqueFazemos />

    <Resultados />

    <Objetivos/>

    <Fim/>
   
    </>
   
  );  
}

export default Index;