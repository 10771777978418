import React, { useState, useEffect, useRef } from "react";
import { CssMarcas } from "./cssMarcas";

import M1Suvinil from '@/assets/imagens/logo-suvinil.png'
import M1Evino from '@/assets/imagens/logo-evino.png'
import M1Ecom from '@/assets/imagens/logo-ecom.png'

import M2Neo from '@/assets/imagens/logo-neo.png'
import M2Edp from '@/assets/imagens/logo-edp.png'
import M2Saingoban from '@/assets/imagens/logo-saingoban.png'

import M3Basf from '@/assets/imagens/logo-basf.png'
import M3Financiamento from '@/assets/imagens/logo-financiamento.png'

const Marcas = () => { 
 
  const [showConfia, setShowConfia] = useState(false);
  const quemConfiaRef = useRef(null);
  const alugmasMarcasRef = useRef(null);

  useEffect(() => {
   
    const handleScroll = () => {
      const confianca = document.getElementById("quemconfia");
      console.log("🚀 ~ handleScroll ~ confianca:", confianca)
  
      if (!confianca) return;
  
      const rectF = confianca.getBoundingClientRect();
      const faixa = rectF.top;
  
      if (faixa <= window.innerHeight -100) {
        setShowConfia(true);
        window.removeEventListener("scroll", handleScroll);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <CssMarcas>       
      <div ref={quemConfiaRef} id="quemconfia" className={showConfia ? "apareceConfia" : ""}>
        Quem confia na ZestOne
      </div>

      <div ref={alugmasMarcasRef} id="alugmasMarcas" className={showConfia ? "apareceTexto" : ""}> 
        ALGUMAS MARCAS QUE<span/> <strong>ESTABELECEMOS CONEXÕES</strong>
      </div>

      <div id="faixa" className={showConfia ? "visivelFaixa" : ""}>

        <div id="marcas1" className={showConfia ? "visivelmarcas1" : ""} >
          <img src={M1Suvinil} alt="Suvinil" title="Suvinil" className="img"/>
          <img src={M1Evino} alt="Evino" title="Evino" className="img"/>
          <img src={M1Ecom} alt="Suvinil" title="Suvinil" className="img"/>
        </div>

        <div id="marcas2" className={showConfia ? "visivelmarcas2" : ""} >
          <img src={M2Neo} alt="Neo" title="Neo" className="img"/>
          <img src={M2Edp} alt="EDP" title="EDP" className="img"/>
          <img src={M2Saingoban} alt="Saint Gobain" title="Saint Gobain" className="img"/>
        </div>

        <div id="marcas3" className={showConfia ? "visivelmarcas3" : ""} >
          <img src={M3Basf} alt="Neo" title="Neo" className="img"/>
          <img src={M3Financiamento} alt="EDP" title="EDP" className="img"/>
        </div>


      </div>
    </CssMarcas>
  );
  
}

export default Marcas;
