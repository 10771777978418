import ReactDOM from 'react-dom';

export const toggleNav = async (id, classe) => {
    //alert('teste')
    const m = document.getElementById("sidebar");       
    let e = document.getElementById(id);
    let p = document.getElementById("padder");

    if (e) {
        if (e.classList.contains(classe)) {
            e.classList.remove(classe);
            p.classList.remove(classe);
            m.classList.add("uk-left-menu-active");
            m.classList.remove("uk-left-menu-inative");
            p.classList.add("content-padder-active");
            p.classList.remove("content-padder-inative");
            return true;//Classe existia
        } else {
            e.classList.add(classe);
            m.classList.remove("uk-left-menu-active");
            m.classList.add("uk-left-menu-inative");
            p.classList.remove("content-padder-active");
            p.classList.add("content-padder-inative");
            return false;//Classe não existia
        }
    } else {
        return -1;
    }
};

export const toggleClasse = async (id, classe) => {   
    var e = document.getElementById(id);

    if (e) {
        if (e.classList.contains(classe)) {
            e.classList.remove(classe);
            return true;//Classe existia
        } else {
            e.classList.add(classe);
            return false;//Classe não existia
        }
    } else {
        return -1;
    }
};

export const DivActive = (id)=>{
    let c = document.getElementsByClassName("active")
    if(c?.[0]?.classList){
        c[0].classList.remove("active")
    }
    
    let element = document.getElementById(id) 
    element.classList.add("active")
}

export const visible = async (id) => {
    if (id) {
        var x = document.getElementById(id);
        if (!x) {
            return -1;
        }
        if (window.getComputedStyle(x).getPropertyValue("display") === "none") {
            ReactDOM.findDOMNode(x).style.setProperty("display", "block");         
            return true;
        } else {
            ReactDOM.findDOMNode(x).style.setProperty("display", "none");           
            return false;
        }
    } else {
        return -1;
    }
};
