import styled from "styled-components";
import FundoRosa from "../../assets/imagens/fundoRosa.jpg";

export const CssHome = styled.div`

  width: 100%;
  height: 100vh;
  position: relative;
  padding: 0;
  margin: 0;
  background: url(${FundoRosa});
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow-x: hidden;

  #principal{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    position: relative;
    padding: 0;
    margin: 0;
    overflow: hidden;
    
    #logo{
      margin: 0 auto; 
      padding: 20px;
      
      img{
        max-width:500px
      }   
    }

    @keyframes fadeOut {
      0% {
        opacity: 1; /* Começa completamente visível */
      }
      100% {
        opacity: 0; /* Termina completamente transparente */
      }
    }

    .esconder-logo{
      animation: fadeOut 1s forwards;
    }
  }

    /* mulher */

    #mulher{
    width: 55%;
    position: absolute;
    bottom: -5px;
    right:-60%;   
    z-index:2
  }

  @keyframes moveFromRightMulher {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-135%);
    }
  }

  .apareceMulher{
    animation: moveFromRightMulher 1.5s forwards;
  }

  /* faixa1 */
  #faixa1{
    position: absolute;
    background: #fff;
    padding: 2% 2% 2% 10%;
    bottom: 0;
    right: 30%;
    width:60%;
    min-height: 380px;
    z-index: 3;
    clip-path: polygon(20% 0, 100% 0, 90% 100%, 0 100%);
    opacity: 0;
    transition: opacity 1s ease-in-out;
    display: flex; /* Adiciona um display flex para centralizar o conteúdo */
    justify-content: center; /* Centraliza horizontalmente */
    align-items: center; /* Centraliza verticalmente */

    p{
      width: 70%;
      line-height: 35px;
      font-size: 18px;
      background: linear-gradient(to right, #ee4d9b, #4157a6); /* Degradê de cores */
      -webkit-background-clip: text; /* Aplica o degradê ao texto */
      -webkit-text-fill-color: transparent; /* Torna o texto transparente */
      background-clip: text; /* Aplica o degradê ao texto */
      display: inline;
    }

    span{
      display:block
    }
  }

  @keyframes VisibleFaixa1 {
    0% {
      opacity: 0;
      right:30%;
    }
    100% {
      opacity: 1;
      right:-10%; 
    }
  }

  .visivelFaixa1{
    animation: VisibleFaixa1 1s forwards; 
  }

  /* faixa2 */
  #faixa2{
    position: absolute;
    background: #fff;
    padding: 20px;
    top: 0;
    right: 30%;
    width:75%;
    min-height: 50%;
    z-index: 0;
    clip-path: polygon(18% 0, 100% 0, 90% 100%, 0 100%);
    opacity: 0;
    transition: opacity 1s ease-in-out;
  }

  @keyframes VisibleFaixa2 {
    0% {
      opacity: 0;
      right:30%;
    }
    100% {
      opacity: 1;
      right:-15%; 
    }
  }

  .visivelFaixa2{
    animation: VisibleFaixa2 1s forwards; 
  }

  
  /* texto */
  #texto{
    position: absolute;
    width: 30%;
    left:0;
    top: 15%;
    height:70%;
    font-size: 16px;
    color: #fff;  
    opacity: 0;
    transition: opacity 1s ease-in-out;  
  }

  #somos{
    position: absolute;
    top: 0%;
    left: 2%;
    font-size: 25px;
    letter-spacing: 10px;
  }
  
  #zest{
    position: absolute;
    top: 6%;
    left: -1%;
    font-family: "Roboto", sans-serif;
    font-optical-sizing: auto;
    font-weight:700;
    font-size: 11rem;
    line-height: 145px;
    span{
      display: block
    }
  }

  @keyframes VisibleZest {
    0% {
      opacity: 0;
      left:-50%;
    }
    100% {
      opacity: 1;
      left:5%; 
    }
  }

  .mostraZest{
    animation: VisibleZest 1s forwards; 
  }

  #one{
    position: absolute;
    top: 24%;
    left: -50%;
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight:700;
    font-size: 12rem;
    color: #fff;
  }

  @keyframes VisibleOne {
    0% {
      opacity: 0;
      left:-50%;
    }
    100% {
      opacity: 1;
      left:0%; 
    }
  }

  .mostraOne{
    animation: VisibleOne 0.8s forwards; 
  }

  #textoCurto{
    color: #fff;
    font-size: 20px;
    position: absolute;
    top: 60%;
    left: 3%;
    opacity: 0;
    transition: opacity 1s ease-in-out;  
    line-height: 35px;

    span{
      display: block;
    }
  }

  @keyframes VisibleCurto {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1; 
    }
  }

  .mostraTextoCruto{
    animation: VisibleCurto 2s forwards; 
  }
  
 #loguinho{
  position: absolute;
  bottom: -50%;
  left: 0;

  img{
    width: 120px;
  }
 }


 @keyframes VisibleLoguinho {
    0% {
      bottom: -50%;
      left: 0;
    }
    100% {
      bottom: 0%; 
      left: 1%;
    }
  }

  .mostraLoguinho{
    animation: VisibleLoguinho 1s forwards; 
  }

  @media only screen and  (max-width: 1550px){
    
    @keyframes moveFromRightMulher {
      0% {
        transform: translateX(100%);
      }
      100% {
        transform: translateX(-100%);
      }
    }

    #mulher{
      width:53%;  
      right: -50%;  
    }

    #faixa1{
      min-height: 300px;
      padding: 3% 2% 2% 4%
    }
    #texto{
      top: 5%;
     height: 90%;
    }

    #textoCurto{
      top: 50%;
    }
   
   
 } 

  @media only screen and  (max-width: 1370px){
    
    #textoCurto {
    top: 55%;
}

    @keyframes moveFromRightMulher {
      0% {
        transform: translateX(100%);
      }
      100% {
        transform: translateX(-100%);
      }
    }

    #mulher{
      width:65%; 
      right: -65%;   
    }

    #texto {
      top: 10%;
      height: 83%;
   } 

   #faixa1{
    width: 65%;
   }
   
  }


  @media only screen and  (max-width: 1280px){

    @keyframes moveFromRightMulher {
      0% {
        transform: translateX(100%);
      }
      100% {
        transform: translateX(-85%);
      }
    }

    #mulher{
      width:72%;    
    }

     #texto {
      width: 35%;
      top: 15%;
      height: 70%;
    }

    #zest {
      top: 12%;
      font-size: 8rem;
      line-height: 100px;
    }

     #one {     
      top:24%;
      font-size: 9rem;
    }

    #textoCurto {
      top: 55%;
    }

  }


  @media only screen and  (max-width:1024px){

    #faixa1 {
        width: 80%;
        min-height: 247px;
    }

    #textoCurto span {
        display: none;
    }

    #loguinho{
      top: 85%
    }

  }
 

  @media only screen and  (max-width: 915px){

    #mulher {
      width: 117%;
      right: -140%;
    }

    #faixa2{
      display: none
    }

    #texto {
        width: 45%;
        top:10%;
        height: 55%;
    }

    #faixa1{
      bottom: -3px;
      right: 30%;
      width: 95%;
      padding: 3% 2% 2% 10%;

      p{
        width: 75%;
        line-height: 25px;
        font-size:16px;
      }
    }
  }

  @media only screen and  (max-width: 480px){

    #textoCurto span {
        display: none;
    }
   
    #texto {
      width:50%;
      top: 5%;
      height: 55%;
    }

     #faixa1 span {
        display: none;
    }

     #zest {
        font-size: 5rem;
        line-height: 65px;
    }

   #one {
      top: 20%;
      font-size: 7rem;
   }

   #textoCurto {
      top: 48%;
   }

    #loguinho {
      top: 110%;
   } 

   #mulher{
    width: 135%;   
    right: -165%;
   }

    #faixa1 {
      bottom: -3px;
      right: 30%;
      width: 110%;
      min-height: 160px
    }

     #faixa1 p {
      font-size: 14px;
    }

  }

  @media only screen and  (max-width: 420px){

     #mulher {
      width: 124%;
      right: -165%;
      bottom: 60px;
    }

    #textoCurto{
      line-height: 27px;
      font-size: 15px;
    }

    #zest {
      top: 6%;
      font-size: 4rem;
      line-height: 55px;
   }

   #somos {
        font-size: 12px;
    }

    #faixa1 p {
        font-size: 12px;
    }

   #one {
    top: 22%;
    font-size: 5rem;
  }

  #texto {
    width: 53%;
    top: 7%
  }

  #faixa1 {    
    min-height: 130px;
    padding: 2% 2% 2% 15%;
    bottom: 60px;
  }

  #loguinho{
    top: -15%;
    left: 125% !important;

    img{
      width: 100px;
    }

  }

  }

  @media only screen and  (max-width: 395px){
  
     #loguinho {
      top: -5%;
      left: 105% !important;
  }

    #texto {
      width: 55%;
      top: 3%;
      height: 55%;
   }

   #somos{
    top:9%
   }

   #zest {
     top: 15%;
      font-size: 5rem;
      line-height: 70px;
    }

    #mulher {
      width: 145%;
      right: -195%;
      bottom: 45px;
   }

    #one {
      top: 25%;
      font-size: 6rem;
    }

    #faixa1 {
      min-height:140px;
      bottom: 45px;
   }

    #textoCurto {
      top: 55%;
    }

  }

  @media only screen and  (max-width: 330px){
    
    #loguinho{
      display: none
    }
    #texto {
        width: 62%;
        top: 3%;
        height: 55%;
    }

    #somos{
      font-size:16px;  
    }

     #zest {
      
      font-size: 3rem;
    }

    #one {   
      top: 20%;
      font-size: 4rem;
    }

     #textoCurto {
        top: 50%;
        font-size: 16px;
        line-height: 26px;
    }

   #mulher {
      width: 140%;
      right: -195%;
    }

    #faixa1 p {
      line-height: 20px;
   }

  }


`;