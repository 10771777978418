import styled from "styled-components";
import FundoVerde from "../../assets/imagens/fundoVerde.jpg";

export const CssOquefazemos = styled.div`

  width: 100%;
  height: 100vh;
  position: relative;
  padding: 0;
  margin: 0;
  background: url(${FundoVerde});
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;

   /* faixa1 */
   #faixa1F{
    position: absolute;
    background: #fff;
    padding: 2% 2% 2% 10%;
    top:10%;
    left: -100%;
    width:85%;
    height: 45%;
    z-index: 3;
    clip-path: polygon(35% 0, 100% 0, 80% 100%, 0 100%);
    opacity: 0;
    transition: opacity 1s ease-in-out;
    z-index: 0;
  }

  @keyframes VisibleFaixa1F {
    0% {
      opacity: 0;
      left:-50%;
    }
    100% {
      opacity: 1;
      left:-35%; 
    }
  }

  .visivelFaixa1F{
    animation: VisibleFaixa1F 3s forwards; 
  }
   

   /* faixa2 */
   #faixa2F{
    position: absolute;
    background: #fff;
    padding: 20px;
    bottom: 0;
    left: -50%;
    width: 65%;
    height: 20%;
    z-index: 0;
    clip-path: polygon(28% 0, 100% 0, 90% 100%, 0 100%);
    opacity: 0;
    transition: opacity 1s ease-in-out;
    z-index: 3;
  }

  @keyframes VisibleFaixa2F {
    0% {
      opacity: 0;
      left:-50%;
    }
    100% {
      opacity: 1;
      left:-25%; 
    }
  }

  .visivelFaixa2F{
    animation: VisibleFaixa2F 2s forwards; 
  }


   /* mulher */
   #mulherNegra{
    width: 55%;
    position: absolute;
    bottom: -4px;
    left:-60%;   
    z-index:2
  }

  @keyframes moveFromRightMulher2 {
    0% {
      left:-50%;
    }
    100% {
      left:-5%; 
    }
  }

  .apareceMulherNegra{
    animation: moveFromRightMulher2 2.5s forwards;
  }



 /* faixa2 */
 #faixa3F{
    position: absolute;
    background: transparent;
    border-top:2px #fff solid;
    padding: 20px;
    bottom:0%;
    right: -80%;
    width: 95%;
    height: 55%;
    z-index: 0;
    clip-path: polygon(28% 0, 100% 0, 90% 100%, 0 100%);
    opacity: 0;
    transition: opacity 1s ease-in-out;
    z-index: 1;
  }

  @keyframes VisibleFaixa3F {
    0% {
      opacity: 0;
      right:-50%;
    }
    100% {
      opacity: 1;
      right:-5%; 
    }
  }

  .visivelFaixa3F{
    animation: VisibleFaixa3F 2s forwards; 
  }


    /* oqefazemos */
   #oqfazemos{      
      position: absolute;
      top: 10%;
      right: -100%;   
      z-index:2;
      font-size: 5rem;
      font-family: "Roboto", sans-serif;
      font-optical-sizing: auto;
      font-weight:700;
      color: #fff;
      line-height: 80px;
      
      span{
        display: block
      }

      div{
        color: #fff;
        font-size: 1.2rem;
        line-height: 35px;
        font-family: 'Roboto', sans-serif;
        font-weight:300;
        letter-spacing: 5px;
        padding-top: 20px;
      }
    }

  @keyframes moveFromFazemos {
    0% {
      right: -50%;
    }
    100% {
      right: 18%;
    }
  }

  .aparecerFazemos{
    animation: moveFromFazemos 2s forwards;
  }

  #icones{
    display: flex;
    flex-wrap: wrap;
    position: absolute;
    top:10%;
    padding: 20px;
    font-size: 16px;
    width:45%;
    right: 12%;
  }

  .itens{
    width: 50%; /* Cada item ocupará metade da largura do contêiner pai */
    display: flex;
    align-items: center;
    padding: 20px;
    color: #fff;

  
    
    img{
      max-width: 50px;
      margin-right: 10px;
    }
  }

 
  @media only screen and  (max-width: 1550px){
    
    #oqfazemos{  
      top: 9%;
    }
  
    @keyframes moveFromFazemos {
      0% {
        right: -50%;
      }
      100% {
        right: 11%;
      }
    }

    #icones{    
      top:2%; 
      right: 12%    
    }
   
  } 

  @media only screen and  (max-width: 1370px){
    
   
    @keyframes moveFromFazemos {
      0% {
        right: -50%;
      }
      100% {
        right: 8%;
      }
    }


  }


  @media only screen and  (max-width: 1280px){

    #oqfazemos{
      font-size: 4rem;
    }

    @keyframes moveFromFazemos {
      0% {
        right: -50%;
      }
      100% {
        right: 13%;
      }
    }

    #icones{
      right: 5%;
      width: 55%;
    }

  }


  @media only screen and  (max-width:1024px){

    #mulherNegra{
      width: 65%;
    }

    #oqfazemos {
        font-size: 3rem;
        line-height:55px;
        top: 12%;
    }

    @keyframes moveFromRightMulher2 {
      0% {
        left:-50%;
      }
      100% {
        left:-20%; 
      }
    }

  @keyframes VisibleFaixa1F  {
      0% {
        opacity: 0;
      left: -50%;
      }
      100% {
        opacity: 1;
        left: -45%; 
      }
    }

    @keyframes VisibleFaixa2F  {
      0% {
        opacity: 0;
      left: -50%;
      }
      100% {
        opacity: 1;
        left: -32%;
      }
    }

    #icones {
        width: 65%;
    }

  }
 

  @media only screen and  (max-width: 915px){

    #faixa1F{
      height: 28%;
      display: none
    }

    #mulherNegra {
        width:100%;
    }

    @keyframes moveFromRightMulher2 {
      0% {
        left:-50%;
      }
      100% {
        left:-48%; 
      }
    }
 
    #oqfazemos {
        font-size: 5rem;
        line-height: 90px;
        top: 5%;        
      
    }

    @keyframes moveFromFazemos {
      0% {
        right: -50%;
      }
      100% {
        right: 17%;
      }
    }

  }

  @media only screen and  (max-width: 480px){

    #oqfazemos {
        font-size: 3rem;
        line-height: 50px;
        top: 5%;

        div{
          font-size: 1rem;
        }
    }

    #faixa3F{
      border:none;
      height: 65%;
    }

    #icones {
        width: 70%;
    }

    .itens{
      width: 100%;
      padding: 10px 20px;
    }




  }

  @media only screen and  (max-width: 415px){

    

    #oqfazemos {
      font-size: 40px;
      line-height: 40px;
      top: 5%;
      width: 100%;
      text-align: center;
      right: 0;
      padding: 0 20px;

      span{
        display: none
      }

      div{
        line-height: 25px;
        padding-top: 10px;
      }
  }

  @keyframes moveFromFazemos {
      0% {
        right: -150%;
      }
      100% {
        right: 0%;
      }
    }


  @keyframes moveFromRightMulher2 {
      0% {
        left:-50%;
      }
      100% {
        left:-51%; 
      }
    }

    .itens{
      padding: 10px 5px;
      font-size: 14px;
      img{
        max-width: 35px
      }

    }

    #icones {
      width: 65%;
      top: -2%;
    }


  }

  @media only screen and  (max-width: 395px){
  
    #icones {
      width: 70%;
      top: -2%;
    }

    #faixa2F{
      height: 12%;
    }

    #oqfazemos{
      top: 8%;
    }

  }

  @media only screen and  (max-width: 330px){
    
    #oqfazemos {
        top: 4%;
    }

    #icones {
      top: 0;
      padding: 5px;
  }

  #faixa3F {
      height: 65%;
      padding: 5px;
  }

  .itens img {
    max-width: 25px;
  } 
  
  }


`;