import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
//import { PrivateRoute, PublicRoute } from "./index";
import { Error } from "@/helpers";

import {
  Index
} from "@/pages/index";

const Routes = () => {

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Index} />
        <Route path="*" component={Error} />
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
